<template>
  <div class="tag-data-2">
		<div class="no-data" v-if="tagList.length == 0">
			<a-list :data-source="[]" />
		</div>
		<div class="tag-main" v-else>
			<div class="tab-handles-area">
				<div class="btns">
          <template v-if="!edit">
            <a-button size="small" @click="editHandle"><i class="e-icon edit"></i>编辑</a-button>
            <a-button size="small" class="ml-16" @click="removeTag"><i class="e-icon delete"></i>删除</a-button>
            <a-button size="small" class="ml-16" @click="reload"><i class="e-icon refresh"></i>刷新</a-button>
          </template>
          <template v-else>
            <a-button type="primary" size="small" @click="submitUpdateTag"><i class="e-icon save"></i>保存</a-button>
            <a-button size="small" class="ml-16" @click="cancelUpdateTag"><i class="e-icon close"></i>取消</a-button>
          </template>
				</div>
				<div class="right">
          <div class="search-box" v-clickoutside="clickOutSide">
            <a-input-search class="search-input" placeholder="请输入搜索内容" v-model="searchVal"
              @search="onSearch" 
            />
            <div class="search-result-box" v-show="resultBoxShow">
              <div class="no-result" v-if="searchLoading">
                loading...
              </div>
              <div class="no-result" v-else-if="searchList.length == 0">
                无数据
              </div>
              <div class="result" v-else>
                <div class="top">
                  <span class="t1">名称</span>
                  <span class="t2">编码</span>
                  <span class="t3">路径</span>
                </div>
                <div class="line-wrap">
                  <div class="line" @click="toTagPath(item)" v-for="item in searchList" :key="item.tagId">
                    <span class="t1 inaline">{{item.tagName}}</span>
                    <span class="t2 inaline">-</span>
                    <span class="t3 inaline">-</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
				</div>
			</div>

			<div class="tag-page-content">
				<div class="left-bar">
          <div class="tag-list">
            <div class="line" :class="{active: item.tagId == selectTag.tagId}"
              v-for="(item, index) in tagList" :key="item.tagId"
              @click="selectHandle(item, index)"
            >
              <i class="icon-tag"></i><span class="text">{{item.tagName}}</span>
            </div>
          </div>
				</div>
				<div class="right-content">
					<div class="module">
						<div class="title">
							标签详情
						</div>
            <a-button class="btn-struc" type="primary" @click="openTagStructuredModal">标签结构化</a-button>
						<div class="detail">
							<a-form-model layout="inline" :model="editTagForm" :rules="editTagRules" ref="editTagForm">
								<a-row>
									<a-form-model-item label="标签名称" prop="tagName">
											<span v-if="!edit">{{selectTag.tagName}}</span>
                      <a-input v-else placeholder="请输入标签名称" v-model="editTagForm.tagName" style="width: 200px" />
									</a-form-model-item>
								</a-row>
								<a-row>
									<a-form-model-item label="标签编码">
											<span>-</span>
									</a-form-model-item>
								</a-row>
                <a-row>
									<a-form-model-item label="标签路径">
											<span>-</span>
									</a-form-model-item>
								</a-row>
							</a-form-model>
						</div>
					</div>
					<div class="module" style="margin-top: 60px">
						<div class="title">
							标签使用
						</div>
						<div class="detail">
							<div class="tips">
								该模块正在开发中...
							</div>
						</div>
					</div>

				</div>
			</div>

		</div>

    
    <!-- 新增标签 -->
		<a-modal
      v-model="tagStructuredVisible"
      centered
      title="标签结构化"
      @ok="structureTag"
      :maskClosable="false" 
      :destroyOnClose="true"
      :width="650"
      :bodyStyle="{padding: '16px 60px'}"
      :ok-button-props="{ props: { loading: this.structureTagLoading } }"
    >
      <a-form-model :model="structureTagForm" :rules="structureTagRules" ref="structureTagForm" 
        
      >
        <a-form-model-item label="标签名称" prop="name">
          <a-input placeholder="请输入标签名称" v-model="structureTagForm.name" disabled />
        </a-form-model-item>
        <a-form-model-item label="标签编码" prop="code">
          <a-input placeholder="请输入标签编码" v-model="structureTagForm.code" />
        </a-form-model-item>
        <a-form-model-item label="标签路径" prop="path">
          <a-cascader
						:fieldNames="{
							label: 'tagName',
							value: 'tagId',
							children: 'children'
						}"
						:options="treeData" 
						change-on-select 
						placeholder="请选择标签路径"
					 	@change="structureTagSelectPath" 
            v-model="structureTagForm.path"
					/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

	</div>
</template>

<script>
import { listTag, listTree, updateTag, mergeTag, removeTag, structureTag, searchTag } from '@/api/tag'
import { mapGetters } from 'vuex'
import { validateTagName } from "@/utils/validate"
export default {
	name: 'tag-non-structural',
  computed: {
		...mapGetters(['adminId']),
	},
  inject: ['reload'],
	data() {
    const validateName =  (rule, value, callback) => {
			// console.log(rule, value, callback)
			value = value.trim()
			if (value === '') {
					callback(new Error('请输入标签名称'))
			} else if (!validateTagName(value)) {
					callback(new Error('名称由英文字母、数字、中文字符、下划线、中划线、点组成'))
			} else {
					callback();
			}
		}
    const validateCode =  (rule, value, callback) => {
			// console.log(rule, value, callback)
			value = value.trim()
			if (value === '') {
					callback(new Error('请输入标签编码'))
			} else if (!validateTagName(value)) {
					callback(new Error('编码由英文字母、数字、中文字符、下划线、中划线、点组成'))
			} else {
					callback();
			}
		}
		return {
      tagList: [],
      selectTag: {
        tagId: '',
        tagName: ''
      },
      selectIndex: 0,

      // 编辑标签
      edit: false,
      editTagForm: {
        tagName: '',
      },
      editTagRules: {
				tagName: [{validator: validateName, trigger: 'blur'}],
			},

      // 标签结构化 - 非结构化标签 -> 结构化标签
			tagStructuredVisible: false,
			structureTagLoading: false,
			structureTagForm: {
        path: [],
				name: '',
				code: ''
			},
			structureTagRules: {
        path: [{ required: true, message: '请选择标签路径', trigger: 'change'}],
				name: [{ required: true, message: '请选择标签名称', trigger: 'blur'}],
				code: [{ required: true, validator: validateCode, trigger: 'blur'}]
			},

      // 标签树
      treeData: [],

      // 搜索值
			searchVal: '',
			searchList: [],
			resultBoxShow: false,
			searchLoading: false
    }
  },
  created() {
    this.listTag()
    this.listTree()
  },
  methods: {
    // 标签列表
    listTag() {
      this.$showLoading()
			listTag({
				parentId: -1
			}).then(res => {
				console.log(res)
				if (res.code == 200) {
					if (res.data && Array.isArray(res.data) && res.data.length > 0) {
						this.tagList = res.data
            if (this.selectTag.tagId) {
              let r = this.tagList.some((item, index) => {
                if (item.tagId == this.selectTag.tagId) {
                  this.selectIndex = index
                  return true
                } else {
                  return false
                }
              })
              if (!r) {
                this.selectIndex = 0
                this.selectTag = this.tagList[0]
              } 
            } else {
              this.selectIndex = 0
              this.selectTag = this.tagList[0]
            }
					} else {
						this.tagList = []
					}
				}
			}).catch(err => {
				console.log(err)
			}).finally(() => {
        this.$hideLoading()
      })
		},
    // 获取标签库
    listTree() {
      listTree().then(res => {
        console.log('listTree', res)
        if (res.code == 200) {
          this.treeData = res.data
        }
      }).catch(err => {
        console.log('listTree', err)
      })
    },
    // 点击标签
    selectHandle(item, index) {
      this.selectTag = item
      this.selectIndex = index
    },
    // 编辑标签
    editHandle() {
      this.edit = true
      this.editTagForm.tagName = this.selectTag.tagName
    },
    // 提交编辑的标签
    submitUpdateTag() {
      console.log('editTagForm', this.editTagForm)
      this.$refs['editTagForm'].validate(valid => {
        if (valid) {
          console.log('pass')
          let tagName = this.editTagForm.tagName.trim()
          if (tagName == this.selectTag.tagName) {
            this.edit = false
            return
          }
          this.$showLoading()
          updateTag({
            tagId: this.selectTag.tagId,
            parentId: -1,
            tagName,
            tagLastUpdateAdminId: this.adminId
          }).then(res => {
            console.log(res)
            if (res.code == 200) {
              this.edit = false
              this.tagList[this.selectIndex].tagName = tagName
              this.selectTag.tagName = tagName
            } else if (res.code == 400) {
              this.$confirm({
                title: "该标签名称已存在，是否进行合并？",
                centered: true,
                onOk: () => {
                  console.log("OK");
                  this.$showLoading()
                  mergeTag({
                    tagId: this.selectTag.tagId,
                    tagLastUpdateAdminId: this.adminId
                  }).then(res => {
                    console.log(res)
                    if (res.code === 200) {
                      this.$message.success('标签合并成功')
                      this.edit = false
                      this.listTag()
                      this.selectTag.tagName = tagName
                    } else {
                      this.$message.error(res.msg || '操作失败')
                    }
                  }).catch(err => {
                    console.warn(err)
                  }).finally(() => {
                    this.$hideLoading()
                  })
                },
                onCancel: () => {
                  console.log("Cancel");
                },
              })
            } else {
              this.$message.error(res.msg || '网络异常')
            }
          }).catch(err => {
            console.warn(err)
          }).finally(() => {
            this.$hideLoading()
          })
        }
      })
    },
    // 取消编辑
    cancelUpdateTag() {
      this.edit = false
    },

    // 打开结构化标签弹框
		openTagStructuredModal() {
			console.log('openTagStructuredModal')
			this.tagStructuredVisible = true
      this.structureTagForm.name = this.selectTag.tagName
		},
    // 提交结构化标签
		structureTag() {
			this.$refs['structureTagForm'].validate(valid => {
        console.log('structureTag1', this.structureTagForm)
        if (valid) {
          this.structureTagLoading = true
          let parentId = this.structureTagForm.path[this.structureTagForm.path.length - 1]
          structureTag({
						parentId,
						tagId: this.selectTag.tagId,
						tagCode: this.structureTagForm.code.trim(),
						tagLastUpdateAdminId: this.adminId
					}).then(res => {
						if (res.code == 200) {
							console.log('structureTag', res)
							this.$message.success('标签结构化成功')
							this.tagStructuredVisible = false
							this.selectTag = {}
              this.listTag()
              this.$refs['structureTagForm'].resetFields()
						} else {
							this.$message.error(res.msg || '网络异常')
						}
					}).catch(err => {
						console.warn(err)
					}).finally(() => {
						this.structureTagLoading = false
					})
        }
      })
		},
    // 结构化标签 - 选择路径
    structureTagSelectPath(value) {
      console.log('structureTagSelectPath', value)
    },

    // 删除标签
    removeTag() {
      this.$confirm({
        title: '是否确认删除该标签',
        centered: true,
        onOk: () => {
          console.log("OK")
          this.$showLoading()
          removeTag({
            tagIds: this.selectTag.tagId,
            isDelSubset: true,
            tagLastUpdateAdminId: this.adminId
          }).then(res => {
            console.log(res)
            if (res.code === 200) {
              this.$message.success(`删除成功`)
              this.selectTag = {}
              this.listTag()
            } else {
              this.$message.error(res.msg || '操作失败')
            }
          }).catch(err => {
            console.warn(err)
          }).finally(() => {
            this.$hideLoading()
          })
        },
        onCancel: () => {
          console.log("Cancel");
        },
      });
    },

    // 搜索
    onSearch() {
      console.log('onSearch')
			this.searchLoading = true
			this.resultBoxShow = true
			searchTag({
				tagType: 2,
				content: this.searchVal
			}).then(res => {
				console.log(res)
				if (res.code == 200) {
					this.searchLoading = false
					this.searchList = res.data.map(item => {
						let path = item.tagPath.map(v => v.v2).join('/')
						return {
							...item,
							path
						}
					})
				}
			}).catch(err => {
				this.searchLoading = false
				console.log(err)
			})
    },
		onFocusSearch() {
			if (this.searchList.length>0) {
				this.resultBoxShow = true
			}
		},
		clickOutSide() {
			// console.log('clickOutSide')
			if (this.resultBoxShow) {
				this.resultBoxShow = false
			}
		},
    toTagPath(item) {
      this.selectTag = item
    }
  }

}
</script>

<style lang="less">
.tag-data-2 {
  height: 100%;
	.no-data {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		min-height: 100%;
		background: #fff;
	}
  .tag-main {
    height: 100%;
    .search-box {
      position: relative;
      .search-input {
          width: 240px;
          .ant-input {
              background-color: transparent;
              border-radius: 4px;
              border-color: #C4C6CF;
          }
      }
    }
    .tag-page-content {
      height: calc(~"100% - 50px");
      background: #fff;
      border: 1px solid #DCDEE3;
      border-top: none;
      display: flex;
      .left-bar {
        padding: 0;
        height: 100%;
        min-width: 210px;
        border-right: 1px solid #DCDEE3;
        box-sizing: border-box;
        overflow-x: hidden;
        overflow-y: auto;
        .tag-list {
          padding: 16px 0;
          .line {
            height: 28px;
            padding-left: 24px;
            display: flex;
            font-size: 0;
            color: #000;
            align-items: center;
            margin-bottom: 8px;
            cursor: pointer;
            &:last-child {
              margin-bottom: 0;
            }
            .icon-tag {
              width: 12px; height: 12px;
              background: url(../../assets/icons/icon_tag.png) no-repeat;
              background-size: cover;
              margin-right: 16px;
            }
            .text {
              font-size: 12px;
            }
            &.active {
              background: #F3F4F7;
              .text {
                color: #0267ED;
              }
            }
            &:hover {
              background: #F3F4F7;
            }
          }
          
        }
      }
      .right-content {
        padding: 24px 40px;
        flex: 1;
        .module {
          position: relative;
          .title {
            font-size: 16px;
            margin-bottom: 16px;
          }
          .btn-struc {
            position: absolute;
            right: 120px;
            top: 0px;
          }
          .item-content {
            padding-left: 16px;
          }
          .detail {
            .tips {
              color: #999;
              padding: 8px 0;
            }
          }
        }
      }
    }
  }
}
</style>